import Vue from 'vue'
import RootTableMixin from 'GroomyRoot/mixins/Table.js'
import Constants from 'Constants'

export default Vue.util.mergeOptions(RootTableMixin, {

    methods: {
		generateTableConfigShareLink: async function(config_id) {
			const url = this.constructRoute(Constants.TABLE_SHARE_URL, {config_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TableMixin::generateTableConfigShareLink", url, {})
			if(result) return result.retour
			return null
		},

		getShareConfig: async function(config_id) {
			const url = this.constructRoute(Constants.TABLE_SHARE_URL, {config_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TableMixin::getShareConfig", url)
			if(result) return result.retour
			return null
		},

		checkInvoiceSupplierBalance: function(item) {
			return item[0].suppliersocr_balance !== 0
		},

		checkInvoiceSupplierOCR: function(item) {
			return item[0].suppliersocr_ocr_status !== 'waiting'
		},

		checkInvoiceSupplierSameTiers: function(items) {
			return items.every(i => i.suppliersocr_tiers === items[0].suppliersocr_tiers && i.suppliersocr_entity === items[0].suppliersocr_entity)
		},

		checkInvoiceSupplierSolde: function(item) {
			return item[0].suppliersocr_balance == item[0].suppliersocr_ttc
		},

		checkInvoiceSupplierExportReady: function(item) {
			return item[0].suppliersocr_export_status == 'ready' || item[0].suppliersocr_export_status == 'exported'
		},

		checkIfIntraLocalation: function(item) {
			return !item[0].lieu
		},

		checkIfAccountingAccountSupplier: function(item) {
			return item[0].accountingplan_supplier
		}
	}
})