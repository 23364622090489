import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import Mouvements from "@/mixins/Mouvements.js"
import Cache from "@/mixins/Cache.js"
import FileMixin from "@/mixins/File.js"
import Pension from "@/mixins/Pension.js"
import Accounting from "@/mixins/Accounting.js"

import ValidationError from 'GroomyRoot/assets/js/errors/ValidationError'

import ActeTransformer from '@/assets/js/dexie/transformers/ActeTransformer.js'
import ActeTypeTransformer from '@/assets/js/dexie/transformers/ActeTypeTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import HorseContactTransformer from '@/assets/js/dexie/transformers/HorseContactTransformer.js'
import HorseResidenceTransformer from '@/assets/js/dexie/transformers/HorseResidenceTransformer.js'
import RaceTransformer from '@/assets/js/dexie/transformers/RaceTransformer.js'
import RobeTransformer from '@/assets/js/dexie/transformers/RobeTransformer.js'
import SexeTransformer from '@/assets/js/dexie/transformers/SexeTransformer.js'
import MediaPlaylistTransformer from '@/assets/js/dexie/transformers/MediaPlaylistTransformer.js'
import MouvementTransformer from '@/assets/js/dexie/transformers/MouvementTransformer.js'
import SeasonTransformer from '@/assets/js/dexie/transformers/SeasonTransformer.js'
import SeasonMareTransformer from '@/assets/js/dexie/transformers/SeasonMareTransformer.js'
import SeasonMareStallionTransformer from '@/assets/js/dexie/transformers/SeasonMareStallionTransformer.js'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js';
import TiersHorseTransformer from '@/assets/js/dexie/transformers/TiersHorseTransformer'
import PedigreeTransformer from '@/assets/js/dexie/transformers/PedigreeTransformer.js';
import CategorieTransformer from '@/assets/js/dexie/transformers/CategorieTransformer.js'

import HorseResidenceCleaner from '@/assets/js/cache/cleaners/HorseResidenceCleaner.js';
import HorseCategorieCleaner from '@/assets/js/cache/cleaners/HorseCategorieCleaner.js';
import HorseCleaner from '@/assets/js/cache/cleaners/HorseCleaner.js';
import Countries from '../assets/lang/countries/countries.js'

import _cloneDeep from 'lodash/cloneDeep'
import _uniqWith from 'lodash/uniqWith'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import { upperCase } from 'lodash';

var HorseMixin = {
    mixins: [FileMixin, Mouvements, Pension, Accounting, Cache],

	methods: {
		/* Retourne true si le user a les droits d'ajouter un nouveau cheval */
		checkCanAdd: async function() {		
			const horseLicenceLimit = this.getUserHorseLimit()

			return this.$storage.db.t('horse')
                .then(table => {
                    return table.orderBy('horse_id')
                })
                .then(col => {
					return col.primaryKeys()
				})
				.then(res => {
					return res.length < horseLicenceLimit
				})
		},

		getNbHorse: async function() {		
			return this.$storage.db.t('horse')
                .then(table => {
                    return table.orderBy('horse_id')
                })
                .then(col => {
					return col.primaryKeys()
				})
				.then(res => {
					return res.length
				})
		},

        /* Retourne la liste des chevaux appartenant à cette licence, avec le nom des parents, et photo de profil */
        getHorses: async function() {
            const limitHorses = this.getUserHorseLimit()

            return this.$storage.db.t('horse')
                .then(table => {
                    return table.where('horse_inactive').equals(0)
					.limit(limitHorses)
					.toArray()
                })
        },

        /* Retourne les ids des chevaux (valide = 1) appartenant à un utilisateur */
        getValidHorsesByUser: async function(user_licence_key, user_licence_id) {
			const params = { 'user_licence_key': user_licence_key, 'user_licence_id':user_licence_id }
            const url = this.constructRoute(Constants.HORSE_BY_USER, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::getValidHorsesByUser", url)
            .catch(error => {
                console.error("HorseMixin::getValidHorsesByUser => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

		getHorsesWhishlist: async function(horse_ids) {
			const parsed_ids = horse_ids.map(id => parseInt(id))

            return this.$storage.db.t('horse')
                .then(table => {
                    return table
					.where('horse_id')
					.anyOf(parsed_ids)
					.sortBy('horse_nom')
                })
        },

        /* Retourne les détails du cheval à partir de son id - race, robe, sexe, age, photo de profil, nom, prénom, ... */
        getHorseById: async function(horse_id) {
			const table = await this.$storage.db.t('horse')
			horse_id = parseInt(horse_id)

			const horse = await table.get(horse_id)
            return HorseTransformer.process(horse, 'heavy')
        },

		getHorsesByIds: async function(horse_ids, transformer = null) {
			const parsed_ids = horse_ids.map(id => parseInt(id))

            return this.$storage.db.t('horse')
                .then(table => {
                    return table
					.where('horse_id')
					.anyOf(parsed_ids)
                })
				.then(col => {
					if (transformer) return col.transform(new HorseTransformer(transformer))
					return col.transform(new HorseTransformer())
				})
        },

        /* Retourne le pédigrée d'un cheval, à partir de son id */
        getHorsePedigree: async function(horse_id) { // HORSE_PEDIGREE_URL
            return this.$storage.db.t('horse_pedigree')
                .then(table => {
                    return table.where({ pedigree_horse: parseInt(horse_id) })
                }).then(col => {
                    return col.first()
                }).then(async pedigree => {
					if(!pedigree) {
						return {}
					}

					const pedigree_length = 14		
					for(let i=1; i<pedigree_length+1; i++) {
						let pedigree_key = `pedigree_sireencode_${i}`
						if(pedigree[pedigree_key]!=null) {
							let horse = await this.getHorseBySireEncode(pedigree[pedigree_key])
							pedigree[`pedigree_horse_${i}`]=horse
						}
					}
					return pedigree
				}
			)
        },

		/* get Horse Id from sireencode */
		getHorseBySireEncode: async function(sireencode) {
			if(sireencode == null) {
				return null
			}
			// si mon sireencode commence par un _ alors j'ai l'id du cheval
			if(Array.from(sireencode)[0] == '_') {
				const id = sireencode.substring(1);
				return this.$storage.db.t('horse')
	                .then(table => {
	                    return table.where({ horse_id: id })
	                }).then(col => {
	                    return col.first();
	            })
			}
			return this.$storage.db.t('horse')
                .then(table => {
                    return table.where({ horse_sireencode: sireencode })
                }).then(col => {
                    return col.first();
            })
		},

        /* Retourne la ligne de hors_residence, pour la résidence affectée au horse_id - résidence principale */
        getHorseMainResidence: async function(horse_id, force=false) {
			return this.$storage.db.t('horse_residence')
            .then(table => {
                return table.where({
					horseresidence_horse: parseInt(horse_id)
				})
            })
			.then(col => {
				return col.transform(new HorseResidenceTransformer('withLieu'))
			})
			.then(res => {
				if(!res || res.length === 0) return null
				return _orderBy(res, 'horseresidence_id', 'desc')[0]
			})
        },

        getHorseLastResidence: async function(horse_id, force=false) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.where({
					mouvement_horse: parseInt(horse_id),
					mouvement_type: 1
				})
            })
			.then(col => {
				return col.transform(new MouvementTransformer())
			})
			.then(res => {
				if(!res || res.length === 0) return null
				return _orderBy(res, ['mouvement_date', 'mouvement_id'], ['desc', 'desc'])
			})
        },

        /* Lieu actuel (peut être une sortie) */
        getHorseActualPlace: async function(horse_id, force=false) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.where({
					mouvement_horse: parseInt(horse_id)
				})
            })
			.then(col => {
				return col.filter(mouv => mouv.mouvement_type != 5)
					.transform(new MouvementTransformer())
			})
			.then(res => {
				if(!res || res.length === 0) return null
				const date_now = new Date()

				const temp = res.filter(mouv => {
					const date_mouv = new Date(mouv.mouvement_date)
					return date_mouv <= date_now
				})

				const temp2 = _orderBy(temp, ['mouvement_id_absolute'], ['desc'])
				return temp2.length > 0 ? temp2[0] : null
			})
        },

        /* Lieu par rapport à une date donnée. */
        getHorsePlaceAtDate: async function(horse_id, date) {
			let mouvement = await this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.where({
					mouvement_horse: parseInt(horse_id),
					mouvement_type: 1
				})
            })
			.then(col => {
                return col.transform(new MouvementTransformer())
			})
			.then(res => {
				if(!res || res.length === 0) return null
				const date_now = new Date(date)

				const temp = res.filter(mouv => {
					const date_mouv = new Date(mouv.mouvement_date)
					return date_mouv <= date_now
				})
				
				const temp2 = _orderBy(temp, ['mouvement_id_absolute'], ['desc'])
				return temp2.length > 0 ? temp2[0] : null
			})
			
			if (mouvement){
				let mouvementSortie = await this.$storage.db.t('horse_mouvement')
				.then(table => {
					return table.where({
						mouvement_horse: parseInt(horse_id)
					})
				})
				.then(col => {
					return col.filter(mouv => mouv.mouvement_type != 5 && mouv.mouvement_type != 1 && mouv.mouvement_date > mouvement.mouvement_date && mouv.mouvement_date < new Date(date))
						.transform(new MouvementTransformer())
				})
				return mouvementSortie && mouvementSortie.length > 0 ? null : mouvement
			}
			return null
        },

        /* Retourne tous les mouvements enregistrés sur le horse_id */
        getHorseMouvements: async function(horse_id, force=false) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.where({
					mouvement_horse: parseInt(horse_id)
				})
            })
            .then(col => {
                return col.transform(new MouvementTransformer('withHorseAndType'))
			})
        },

        getInitialProvenance: async function(horse_id) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.where({
					mouvement_horse: parseInt(horse_id),
					mouvement_date: '0000-00-00'
				})
            })
            .then(col => {
                return col.transform(new MouvementTransformer())
			})
			.then(res => {
				if(Array.isArray(res)) return res[0]
				return res
			})
        },

        createHorseOffline: async function(infos) {
            const horse_id = Common.getNegativeId()

            return this.$storage.db.transaction(
                'rw',
                ['horse', 'horse_pedigree'],
                async () => {
		            await this.$storage.db.t('horse')
		            .then(table => {
		                return table.add({
		                    horse_id: horse_id,
		                    horse_datenaissance: infos.horse_datenaissance,
		                    horse_nom: infos.horse_nom,
		                    horse_race: infos.horse_race,
		                    horse_robe: infos.horse_robe,
		                    horse_sexe: infos.horse_sexe,
		                    horse_country: 0,
		                    horse_valide: 1
		                })
					})

		            await this.update_pedigree_horse_manual(horse_id, {})
					
					return horse_id
                }
            )
        },

		devalideHorses: async function(horse_ids) {
			if(!window.navigator.onLine) {
				this.failureToast('toast.suppr_need_online')
				return
			}

			let need_force_sync = false
			const ids = Array.isArray(horse_ids) ? horse_ids : [horse_ids]

			ids.forEach(id => {
				if(id < 0) {
					need_force_sync = true
					return
				}
			})

			if(need_force_sync) {
				await this.$sync.force()
			}

			let tab_promises = []
			ids.forEach(id => {
				tab_promises.push(this.devalideHorse(id))
			})

			return Promise.all(tab_promises)
				.then(() => {
					return new Promise((res, _) => {
						window.setTimeout(res, 1000)
					})
				}) 
				.then(() => {
					return Promise.all([
						this.$sync.runRequiredWhishlist(['horse', 'horse_categorie_lien']),
						this.$sync.runOptionalWhishlist(['horse_actes', 'horse_document_lien', 'horse_mouvement'])
					])
				})
				.then(() => {
					this.successToast('horse.suppression')
				})
				.catch((err) => {
					if (err instanceof ValidationError) {
						throw err
					}
					this.failureToast()
				})
		},

        devalideHorse: async function(horse_id) {
			horse_id = parseInt(this.$sync.replaceWithReplicated('horse', horse_id))

			const params = { 'horse_id': horse_id }
			const url = this.constructRoute(Constants.DELETE_HORSE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			return await this.$request.request_delete_api("HorseMixin::devalideHorse", url)
        },

        /* Ajoute une résidence principale d'un cheval */
        addHorseResidence: async function(horse_id, lieu_id) {
			const horseresidence_id = Common.getNegativeId()
	
			await this.$storage.db.t('horse_residence')
				.then(async (table) => {
					return table.add({
						horseresidence_id: horseresidence_id,
						horseresidence_horse: parseInt(horse_id),
						horseresidence_lieu: parseInt(lieu_id),
						horseresidence_valide: 1
					})
				})

			HorseResidenceCleaner.inst().onMutationHorse([parseInt(horse_id)])

			return horseresidence_id
        },

        /* Met à jour la résidence principale du cheval */
        updateHorseResidence: async function(horse_id, lieu_id) {
			const actual_resid = await this.$storage.db.t('horse_residence')
				.then(table => {
					return table.where({
						horseresidence_horse: parseInt(horse_id)
					})
				})
				.then(col => {
					return col.toArray()
				})

			if(!actual_resid || actual_resid.length === 0) return this.addHorseResidence(horse_id, lieu_id)

			await this.$storage.db.transaction(
				'rw',
				['horse_residence'],
				async () => {
					const table = await this.$storage.db.t('horse_residence')

					await table.where('horseresidence_horse')
						.equals(parseInt(horse_id))
						.modify({
							horseresidence_lieu: parseInt(lieu_id)
						})
				}
			)

			HorseResidenceCleaner.inst().onMutationHorse([horse_id])
		},

		deleteHorseResidence: async function(horse_id) {
			await this.$storage.db.t('horse_residence')
				.then(table => {
					return table
						.where('horseresidence_horse')
						.equals(horse_id)
						.invalid()
				})

			HorseResidenceCleaner.inst().onMutationHorse([horse_id])
        },

        /* Update le sexe et la robe du cheval */
        updateHorseType: async function(horse_id, sexe_code, robe_code, horse_interne, horse_stallion, horse_country, horse_datemort, horse_mort, horse_localisationlivret, horse_carte, horse_commentaire, horse_transpondeur, horse_nom, horse_alias, horse_passeport) {
            let data = {
                horse_stallion: horse_stallion ? 1 : 0
            }

            if(sexe_code) {
                data.horse_sexe = sexe_code
            }

            if(robe_code) {
                data.horse_robe = robe_code
            }

            if(horse_interne || horse_interne == '') {
                data.horse_interne = horse_interne
            }

            if(horse_country) {
                data.horse_country = horse_country
            }

			if(horse_datemort || horse_datemort == '') {
				data.horse_datemort = horse_datemort
				if (horse_mort == 0) {
					data.horse_mort = 1
				}
			}

			if(horse_localisationlivret || horse_localisationlivret == '') {
                data.horse_localisationlivret = horse_localisationlivret
            }

			if(horse_carte || horse_carte == '') {
                data.horse_carte = horse_carte
            }

			if(horse_commentaire || horse_commentaire == '') {
                data.horse_commentaire = horse_commentaire
            }

            if(horse_transpondeur) {
            	data.horse_transpondeur = horse_transpondeur
            }

			if(horse_nom) {
            	data.horse_nom = horse_nom
            }

			if(horse_alias) {
            	data.horse_alias = horse_alias
            }

            if(horse_passeport) {
            	data.horse_passeport = horse_passeport
            }

            return this.$storage.db.t('horse')
            .then(table => {
                return table.update(parseInt(horse_id), data)
            })
        },

        /* Update tout le cheval si je suis en mode edition */
        updateHorse: async function(horse_id, params) {
            try {
                await this.$storage.db.t('horse')
                .then(table => {
                    return table.update(parseInt(horse_id), params)
                })
                return true
            } catch(err) {
                console.error(err)
                return false
            }
        },

        /* formatte dans le format attendu, pour la liste classique des chevaux */
        formatDataHorse: function(data) {
            let result = []
            // On parcourt la liste des chevaux
            for (let i = 0; i < data.length; i++) {
                let item = data[i];

                item.horse_race = item.race_label
                item.horse_robe = item.robe_label
                item.horse_sexe = item.sexe_label
                item.id = item.horse_id

                if(item.pedigree_nom_1 === 'null' || item.pedigree_nom_1 === null)
                    item.pedigree_nom_1 = 'NC'
                if(item.pedigree_nom_2 === 'null' || item.pedigree_nom_2 === null)
                    item.pedigree_nom_2 = 'NC'

                result.push(item)
            }

            return result
        },
        /* formatte un objet horse de l'api dans le format attendu, pour le tableau */
        formatDataHorseAjout: async function(horse_obj, correspondance) {
            // La correspondance pour la clé de l'objet à manipuler
            let matching = ""
            let obj_retour = {}
            const keys_codes = ['horse_race', 'horse_robe', 'horse_sexe']
            const keys_data = {
                'horse_race': 'getRaceCode',
                'horse_robe': 'getRobeLabel',
                'horse_sexe': 'getSexeLabel',
            }

            // On retourne tableau avec un seul objet formatté
            for (let key in correspondance) {
                matching = correspondance[key]

                // Si on a une valeur nested (dam.___ ou sire.___)
                if(matching.indexOf('.') > -1) {
                    const matching_path = matching.split('.')
                    obj_retour[key] = this.getNestedObject(horse_obj, matching_path)
                }
                else {
                    // Si la clé correspond à une valeur à récupérer en dehors de l'objet (label à partir d'un code par ex)
                    if(keys_codes.indexOf(key) > -1) {
                        const method = keys_data[key]
                        obj_retour[key] = await this[method](horse_obj[matching])
                    }
                    else {
                        obj_retour[key] = horse_obj[matching]
                    }
                }
            }

            return obj_retour
		},

		getRaces() {
			return this.$storage.db.t('horse_race')
			.then(table => {
				return table.orderBy('race_label')
			})
			.then(col => {
				return col.transform(new RaceTransformer())
			})
		},

		getRobes() {
			return this.$storage.db.t('horse_robe')
			.then(table => {
				return table.orderBy('robe_label')
			})
			.then(col => {
				return col.transform(new RobeTransformer())
			})
		},

		getSexes() {
			return this.$storage.db.t('horse_sexe')
			.then(table => {
				return table.orderBy('sexe_label')
			})
			.then(col => {
				return col.transform(new SexeTransformer())
			})
		},

        /* Retourne le label traduit de la race, à partir du localstorage */
        getRaceLabel: function(race_code) {
			if(!race_code) {
				return null
			}

			return this.$storage.db.t('horse_race')
			.then(table => {
				return table.where('race_code').equals(race_code)
			})
			.then(col => {
				return col.transform(new RaceTransformer())
			})
			.then(races => {
				return races[0] ? races[0].race_label : null
			})
		},

        getRaceCode: function(race_code) {
			if(!race_code) {
				return null
			}

			return this.$storage.db.t('horse_race')
			.then(table => {
				return table.where('race_code').equals(race_code)
			})
			.then(col => {
				return col.transform(new RaceTransformer())
			})
			.then(races => {
				return races[0] ? races[0].race_code : null
			})
		},

        /* Retourne le label traduit de la robe, à partir du localstorage */
        getRobeLabel: function(robe_code) {
			if(!robe_code) {
				return null
			}

			return this.$storage.db.t('horse_robe')
			.then(table => {
				return table.where('robe_code').equals(robe_code)
			})
			.then(col => {
				return col.transform(new RobeTransformer())
			})
			.then(robes => {
				return robes[0] ? robes[0].robe_label : null
			})
        },
        /* Retourne le label traduit du sexe, à partir du localstorage */
        getSexeLabel: function(sexe_code) {
			if(!sexe_code) {
				return null
			}

			return this.$storage.db.t('horse_sexe')
			.then(table => {
				return table.where('sexe_code').equals(sexe_code)
			})
			.then(col => {
				return col.transform(new SexeTransformer())
			})
			.then(sexes => {
				return sexes[0] ? sexes[0].sexe_label : null
			})
        },
        /*Retourne la liste des catégories disponibles pour la licence */
        getAllCategorie: function(lang=true) {
            return this.$storage.db.t('horse_categorie')
                .then(table => {
                    return table.toCollection()
                })
                .then(col => {
                    return col.sortBy('categorie_libelle')
                })
                .then(col => {
                	if(lang) {
						return CategorieTransformer.process(col, 'withLang')
                	}
                	return col
                })
        },

        async getHorseCategories(horse_id, lang=true) {
            const liens = await this.$storage.db.t('horse_categorie_lien')
            .then(table => {
                return table
                .where({ 'lien_horse': parseInt(horse_id) })
                .with({ 'categorie': 'lien_categorie' })
            })

            let categories = []
            liens.forEach(lien => {
                categories.push(lien.categorie)
            })

            if(lang) {
	            categories = CategorieTransformer.process(categories, 'withLang')
            }

            return categories
        },

        addHorseCategorie: async function(horse_id, categorie_id) {
            await this.$storage.db.t('horse_categorie_lien')
            .then(table => {
                return table.add({
                    lien_id: Common.getNegativeId(),
                    lien_horse: horse_id,
                    lien_categorie: categorie_id
                })
            })
			HorseCategorieCleaner.inst().onMutationHorse([horse_id])
        },

        removeHorseCategorie: async function(horse_id, categorie_id) {
            await this.$storage.db.t('horse_categorie_lien')
            .then(table => {
                return table.where({
                    lien_horse: parseInt(horse_id),
                    lien_categorie: parseInt(categorie_id)
                })
            }).then((col) => {
                return col.delete()
            })
			HorseCategorieCleaner.inst().onMutationHorse([horse_id])
        },

		removeAllHorseCategorie: async function(horse_id) {
            await this.$storage.db.t('horse_categorie_lien')
            .then(table => {
                return table.where({
                    lien_horse: parseInt(horse_id)
                })
            }).then((col) => {
                return col.delete()
            })
			HorseCategorieCleaner.inst().onMutationHorse([horse_id])
        },

        addCategorie: async function(categorie_libelle) {
            await this.$storage.db.t('horse_categorie')
            .then(table => {
                return table.add({
                    categorie_id: Common.getNegativeId(),
                    categorie_libelle
                })
            })
        },

        updateCategorie: async function(categorie_id, categorie_libelle, categorie_libelle_en, categorie_public) {
            await this.$storage.db.t('horse_categorie')
            .then(table => {
                return table.update(categorie_id, {
                    categorie_libelle,
                    categorie_libelle_en,
					categorie_public
                })
            })
			HorseCategorieCleaner.inst().onMutation([categorie_id])
        },

		saveCategoriesOrder: function(categories) {
			let tab_promises = []

			categories.forEach((cat, idx) => {
				tab_promises.push(new Promise((res, _) => {
					// Copie des variables à l'instant T du foreach, pour la résolution de la promise
					const clone = _cloneDeep(cat)
					const order = idx

					this.$storage.db.t('horse_categorie')
						.then(table => {
							return table.update(clone.categorie_id, {
								categorie_order: order
							})
						})
						.then(() => {
							res()
						})
				}))
			})

            return Promise.all(tab_promises)
        },

        removeCategorie: async function(categorie_id) {
        	let horse_ids = await this.$storage.db.t('horse_categorie_lien')
            .then(table => {
                return table.where('lien_categorie').equals(categorie_id)
            })
			.then(col => {
				return col.toArray()
			})
			.then(categorie => {
				return Array.from(new Set(categorie.map(r => r.lien_horse)))
			})

            // Supprimer les liens
            await this.$storage.db.t('horse_categorie_lien')
            .then(table => {
                return table.where('lien_categorie').equals(categorie_id)
            })
            .then(col => {
                return col.delete()
            })

            // Supprimer la categorie
            await this.$storage.db.t('horse_categorie')
            .then(table => {
                return table.delete(categorie_id)
            })
			HorseCategorieCleaner.inst().onMutation([categorie_id])
			HorseCleaner.inst().onMutation(horse_ids, ['horse_categories'])
        },

        updateHorseTranspondeur: async function(horse_id, transpondeur) {
            return this.$storage.db.t('horse')
            .then(table => {
                return table.update(parseInt(horse_id), {
                    horse_transpondeur: transpondeur
                })
            })
        },

        updateHorseTaille: async function(horse_id, horse_taille) {
            return this.$storage.db.t('horse')
            .then(table => {
                return table.update(parseInt(horse_id), {
                    horse_taille
                })
            })
		},
		
		async getOrCreateMainPlaylist(horse_id) {
			const table = await this.$storage.db.t('horse_media_playlist')
			let playlist = await table.where({
				mediaplaylist_horse: parseInt(horse_id),
				mediaplaylist_type: 'image',
				mediaplaylist_libelle: 'Photo de profil'
			}).first()

			if (!playlist) {
				playlist = {
					mediaplaylist_id: Common.getNegativeId(),
					mediaplaylist_horse: parseInt(horse_id),
					mediaplaylist_libelle: 'Photo de profil',
					mediaplaylist_type: 'image',
					mediaplaylist_order: 1,
					mediaplaylist_valide: 1
				}
				await table.add(playlist)
			}

			return MediaPlaylistTransformer.process(playlist)
		},

        async uploadMedia (file, horse_id) {
			const ALLOWED_TYPES = [
				"image/png",
				"image/jpeg"
			]

			if (ALLOWED_TYPES.indexOf(file.type) === -1) {
				throw new Error('Invalid file type')
			}

			let filename
			const mediaTable = await this.$storage.db.t('horse_media')
			const playlist = await this.getOrCreateMainPlaylist(horse_id)

			await this.$storage.db.transaction(
				'rw',
				['_files', 'horse_media_playlist', 'horse_media'],
				async () => {
					filename = this.$sync.uploadFile(file)

					await mediaTable
					.where('media_playlist')
					.equals(playlist.mediaplaylist_id)
					.invalid()

					mediaTable.add({
						media_id: Common.getNegativeId(),
						media_horse: parseInt(horse_id),
						media_playlist: playlist.mediaplaylist_id,
						media_type: 'image',
						media_filename: filename,
						media_order: 1,
						media_valide: 1
					})
				}
			)
			return filename
        },
		
        async deletedMedia (filename, horse_id) {

			const playlist = await this.getOrCreateMainPlaylist(horse_id)

			await this.$storage.db.t('horse_media')
			.then(table => {
				return table.where({
					media_playlist: playlist.mediaplaylist_id,
					media_filename: filename,
					media_horse: parseInt(horse_id)
				}).modify({media_valide: 0 })
			})
        },

        search_sire_horse_manual: async function(input, horse_id){

            let url = Constants.SEARCH_SIRE_URL + '?sire='+input+'&horse_id='+horse_id+'&valide=1&update=1&licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_get_api("HorseMixin::search_sire_horse_manual", url, false, { sync: true }).catch(error => {
                console.error("HorseMixin::search_sire_horse_manual => ERROR", error)
                this.failureToast("toast.info_save_failed")
                return response.code_retour
            })


            if(response.code_retour === 0) {
                if(response.retour) {
                    return response
                }
            }
            else {
                return response.code_retour
            }
        },

        refreshSireInfos(horse_id) {
            const url = this.constructRoute(Constants.HORSE_REFRESH_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_post_api("HorseMixin::refreshSireInfos", url, {}, false, { sync: true })
        },


        update_pedigree_horse_manual: async function(horse_id, params){
			let pedigree = await this.$storage.db.t('horse_pedigree')
				.then(table => {
					return table.where('pedigree_horse')
					.equals(this.$sync.replaceWithReplicated('horse', horse_id))
					.first()
				})

			if(pedigree) {
				return this.$storage.db.t('horse_pedigree')
				.then(table => {
					return table.update(pedigree.pedigree_id, params)
				})
			}
			else {
				return this.$storage.db.t('horse_pedigree')
					.then(table => {
						return table.add({
							pedigree_id: Common.getNegativeId(),
							pedigree_horse: this.$sync.replaceWithReplicated('horse', horse_id),
							pedigree_nom_1: params.pedigree_nom_1 || 'NC',
							pedigree_nom_2: params.pedigree_nom_2 || 'NC',
							pedigree_nom_3: params.pedigree_nom_3 || 'NC',
							pedigree_nom_4: params.pedigree_nom_4 || 'NC',
							pedigree_nom_5: params.pedigree_nom_5 || 'NC',
							pedigree_nom_6: params.pedigree_nom_6 || 'NC',
							pedigree_nom_7: params.pedigree_nom_7 || 'NC',
							pedigree_nom_8: params.pedigree_nom_8 || 'NC',
							pedigree_nom_9: params.pedigree_nom_9 || 'NC',
							pedigree_nom_10: params.pedigree_nom_10 || 'NC',
							pedigree_nom_11: params.pedigree_nom_11 || 'NC',
							pedigree_nom_12: params.pedigree_nom_12 || 'NC',
							pedigree_nom_13: params.pedigree_nom_13 || 'NC',
							pedigree_nom_14: params.pedigree_nom_14 || 'NC',
							pedigree_sireencode_1: params.pedigree_sireencode_1 || null,
							pedigree_sireencode_2: params.pedigree_sireencode_2 || null,
							pedigree_sireencode_3: params.pedigree_sireencode_3 || null,
							pedigree_sireencode_4: params.pedigree_sireencode_4 || null,
							pedigree_sireencode_5: params.pedigree_sireencode_5 || null,
							pedigree_sireencode_6: params.pedigree_sireencode_6 || null,
							pedigree_sireencode_7: params.pedigree_sireencode_7 || null,
							pedigree_sireencode_8: params.pedigree_sireencode_8 || null,
							pedigree_sireencode_9: params.pedigree_sireencode_9 || null,
							pedigree_sireencode_10: params.pedigree_sireencode_10 || null,
							pedigree_sireencode_11: params.pedigree_sireencode_11 || null,
							pedigree_sireencode_12: params.pedigree_sireencode_12 || null,
							pedigree_sireencode_13: params.pedigree_sireencode_13 || null,
							pedigree_sireencode_14: params.pedigree_sireencode_14 || null
						})
					})
			}
        },

        loadTiersCurrentPart: async function(horse_id) {
			// let a = await this.$storage.db.t('horse')
			// .then(table => {
			// 	return table.get(parseInt(horse_id))
			// }).then(horse => {
			// 	return HorseTransformer.process(horse, 'withTiersAndCurrentPart')
			// })

			let tiers = await this.loadTiersHeavyCurrentPart(horse_id)

			return {tiers_horse: tiers.filter(tier => !tier.zero)}
        },

        loadTiersHeavyCurrentPart: async function(horse_id, hasSaillie = true) {
			let tiershorsepart_ids = []
			let parts = await this.loadTiersPart(horse_id)

			if(parts) {
                let part_formatted = []
                parts.tiers_horse.forEach((tiers_horse) => {
                    tiers_horse.tiers_horse_part.forEach((tiers_horse_part) => { 
                    	let sum = tiers_horse_part.tiershorsepart_pension + tiers_horse_part.tiershorsepart_frais + tiers_horse_part.tiershorsepart_contract + tiers_horse_part.tiershorsepart_propriete
                    	if(!hasSaillie) {
                    		sum = tiers_horse_part.tiershorsepart_pension + tiers_horse_part.tiershorsepart_frais + tiers_horse_part.tiershorsepart_propriete
                    	}
                    	if(tiers_horse.tiers) {
	                        part_formatted.push({
	                        	tiershorse_id: tiers_horse.tiershorse_id,
	                        	tiershorse_dps: tiers_horse.tiershorse_dps,
	                        	zero: sum == 0,
	                        	tiers: {
		                            tiers_rs: tiers_horse.tiers.tiers_rs,
		                            tiers_id: tiers_horse.tiers.tiers_id,
		                            tiers_postalcode: tiers_horse.tiers.tiers_postalcode,
									tiers_mail: tiers_horse.tiers.tiers_mail,
									tiers_town: tiers_horse.tiers.tiers_town,
									tiers_address1: tiers_horse.tiers.tiers_address1,
									tiers_address2: tiers_horse.tiers.tiers_address2,
									phones: tiers_horse.tiers.phones,
									pays: tiers_horse.tiers.pays,
								},
								tiers_horse_part: {
		                            tiershorsepart_id: tiers_horse_part.tiershorsepart_id,
		                            tiershorsepart_propriete: tiers_horse_part.tiershorsepart_propriete,
		                            tiershorsepart_pension: tiers_horse_part.tiershorsepart_pension,
		                            tiershorsepart_frais: tiers_horse_part.tiershorsepart_frais,
		                            tiershorsepart_contract: tiers_horse_part.tiershorsepart_contract,
		                            tiershorsepart_date: tiers_horse_part.tiershorsepart_date,
		                            tiershorsepart_entraineur: tiers_horse_part.tiershorsepart_entraineur,
									tiershorsepart_jockey: tiers_horse_part.tiershorsepart_jockey,
									tiershorsepart_gains: tiers_horse_part.tiershorsepart_gains,
									tiershorsepart_tiershorse: tiers_horse_part.tiershorsepart_tiershorse
								}
	                        })
	                    }
                    });
                });
                let part_groupby = _groupBy(part_formatted, 'tiers_horse_part.tiershorsepart_date')
                let dates = Object.keys(part_groupby)
                let dates_sort = dates.sort(function(a, b) {
                	return new Date(b) - new Date(a)
                })

                parts = part_groupby[dates_sort[0]]

                if(!parts) {
                	return []
                }

                tiershorsepart_ids = parts.map(part => part.tiers_horse_part.tiershorsepart_id)

                return part_formatted.filter(part => tiershorsepart_ids.includes(part.tiers_horse_part.tiershorsepart_id))
            }

            return []
        },

        loadTiersPart: async function(horse_id) {
			return this.$storage.db.t('horse')
			.then(table => {
				return table.get(parseInt(horse_id))
			})
			.then(horse => {
				return HorseTransformer.process(horse, 'withTiersAndPart')
			})
        },

        // Ajoute un lien horse/tiers
        addHorseTiers: async function(horse_id, date, tiers) {
			if(date && tiers) {
				return this.$storage.db.transaction(
					'rw',
					['horse', 'tiers_horse', 'tiers_horse_part'],
					async () => {
						let horse = await this.$storage.db.t('horse')
									.then(table => {
										return table
										.where('horse_id')
										.equals(parseInt(horse_id))
									})
									.then(col => {
										return col.first()
									})
	
						if(!horse) {
							throw "global.horse_introuvable"
						}
	
						if(typeof tiers == 'object' && tiers.length) {
							tiers.forEach(async(tier) => {
								let tiershorse_id
								if(!tier.tiershorse_id) {
									tiershorse_id = Common.getNegativeId()
									this.$storage.db.t('tiers_horse')
										.then(table => {
											return table.add({
												tiershorse_id: tiershorse_id,
												tiershorse_horse: horse_id,
												tiershorse_tiers: tier.tiers_id,
												tiershorse_dps: tier.tiershorse_dps,
												tiershorse_fonction: 'PROPRIO',
												tiershorse_valide: 1
											})
										})
								}
								else {
									tiershorse_id = tier.tiershorse_id
									this.$storage.db.t('tiers_horse')
										.then(table => {
											return table.update(tiershorse_id, {
												tiershorse_horse: horse_id,
												tiershorse_tiers: tier.tiers_id,
												tiershorse_dps: tier.tiershorse_dps,
												tiershorse_fonction: 'PROPRIO',
												tiershorse_valide: 1
											})
										})
								}
	
								const dateValue = date.toDateInputValue() 
								let tiers_horse_part = await this.$storage.db.t('tiers_horse_part')
									.then(table => {
										return table.where({
											tiershorsepart_tiershorse: tiershorse_id,
										})
										.and(thp => {
											return thp.tiershorsepart_date.toDateInputValue() === dateValue
										})
										.first()
									})
	
								if(!tiers_horse_part) {
									let tiershorsepart_id = Common.getNegativeId()
									this.$storage.db.t('tiers_horse_part')
									.then(table => {
										return table.add({
											tiershorsepart_id: tiershorsepart_id,
											tiershorsepart_tiershorse: tiershorse_id,
											tiershorsepart_propriete: tier.tiershorsepart_propriete,
											tiershorsepart_pension: tier.tiershorsepart_pension,
											tiershorsepart_frais: tier.tiershorsepart_frais,
											tiershorsepart_gains: tier.tiershorsepart_gains,
											tiershorsepart_contract: tier.tiershorsepart_contract,
											tiershorsepart_entraineur: tier.tiershorsepart_entraineur,
											tiershorsepart_jockey: tier.tiershorsepart_jockey,
											tiershorsepart_date: date,
											tiershorsepart_valide: 1
										})
									})
								}
								else {
									this.$storage.db.t('tiers_horse_part')
									.then(table => {
										return table
										.where('tiershorsepart_id')
										.equals(parseInt(tiers_horse_part.tiershorsepart_id))
										.invalid()
									})

									let tiershorsepart_id = Common.getNegativeId()
									this.$storage.db.t('tiers_horse_part')
									.then(table => {
										return table.add({
											tiershorsepart_id: tiershorsepart_id,
											tiershorsepart_tiershorse: tiershorse_id,
											tiershorsepart_propriete: tier.tiershorsepart_propriete,
											tiershorsepart_pension: tier.tiershorsepart_pension,
											tiershorsepart_frais: tier.tiershorsepart_frais,
											tiershorsepart_gains: tier.tiershorsepart_gains,
											tiershorsepart_contract: tier.tiershorsepart_contract,
											tiershorsepart_entraineur: tier.tiershorsepart_entraineur,
											tiershorsepart_jockey: tier.tiershorsepart_jockey,
											tiershorsepart_date: date,
											tiershorsepart_valide: 1
										})
									})
								}
							})
						}
						else {
							throw "tiers.liste_tiers_introuvable"
						}
	
						return horse_id
					}
				)
			}
			else {
				throw "toast.info_save_failed"
			}
        },

        deleteHorseTiers: async function(horse_id, tiers_id, tiershorse_id, date) {
			if(tiershorse_id) {
				if(!date) {
					date = new Date()
				}
	
				if(horse_id) {
					let horse = await this.$storage.db.t('horse')
					.then(table => {
						return table.get(parseInt(horse_id))
					})
					.then(horse => {
						return horse
					})
	
					if(!horse) {
						throw "global.cheval_introuvable"
					}
				}
				else {
					if(tiers_id) {
						let tiers = await this.$storage.db.t('tiers')
						.then(table => {
							return table.get(parseInt(tiers_id))
						})
						.then(tiers => {
							return tiers
						})
	
						if(!tiers) {
							throw "tiers.tiers_introuvable"
						}
					}
				}
	
				return this.$storage.db.transaction(
					'rw',
					['tiers_horse', 'tiers_horse_part'],
					() => {
						// this.$storage.db.t('tiers_horse')
						// .then(table => {
						// 	return table
						// 	.where('tiershorse_id')
						// 	.equals(parseInt(tiershorse_id))
						// 	.invalid()
						// })
	
						const tiershorsepart_id = Common.getNegativeId()
	
						this.$storage.db.t('tiers_horse_part')
						.then(table => {
							return table.add({
								tiershorsepart_id: tiershorsepart_id,
								tiershorsepart_tiershorse: parseInt(tiershorse_id),
								tiershorsepart_propriete: 0,
								tiershorsepart_pension: 0,
								tiershorsepart_frais: 0,
								tiershorsepart_gains: 0,
								tiershorsepart_contract: 0,
								tiershorsepart_date: date,
								tiershorsepart_valide: 1
							})
						})
					}
				)
			}
			else {
				throw "toast.info_delete_failed"
			}
        },

        duplicateTiersHorsePart: async function(tiers_horse_part, date) {
			let tab_promises = []

        	tiers_horse_part.forEach(th => {
				tab_promises.push(new Promise((res, _) => {
        			const tiershorsepart_id = Common.getNegativeId()

					this.$storage.db.t('tiers_horse_part')
						.then(table => {
							return table.add({
								tiershorsepart_id: tiershorsepart_id,
								tiershorsepart_tiershorse: th.tiershorsepart_tiershorse,
								tiershorsepart_propriete: th.tiershorsepart_propriete,
								tiershorsepart_pension: th.tiershorsepart_pension,
								tiershorsepart_frais: th.tiershorsepart_frais,
								tiershorsepart_gains: th.tiershorsepart_gains,
								tiershorsepart_contract: th.tiershorsepart_contract,
								tiershorsepart_date: date,
								tiershorsepart_valide: 1
							})
						})
						.then(() => {
							res()
						})
				}))
			})

            return Promise.all(tab_promises)
        },

        deleteHorseTiersPart: async function(tiershorsepart_id) {
			if(tiershorsepart_id) {
				return this.$storage.db.transaction(
					'rw',
					['tiers_horse_part', 'tiers_horse'],
					async () => {
						this.$storage.db.t('tiers_horse_part')
						.then(table => {
							return table
							.where('tiershorsepart_id')
							.equals(parseInt(tiershorsepart_id))
							.invalid()
						})

						let tiershorse_id = await this.$storage.db.t('tiers_horse_part')
						.then(table => {
							return table.get(parseInt(tiershorsepart_id))
						})
						.then(tiershorsepart => {
							return tiershorsepart.tiershorsepart_tiershorse
						})

						let tiershorsepart_count = await this.$storage.db.t('tiers_horse_part')
						.then(table => {
							return table
							.where('tiershorsepart_tiershorse')
							.equals(parseInt(tiershorse_id))
							.count()
						})

						if(tiershorsepart_count - 1 == 0) {
							this.$storage.db.t('tiers_horse')
							.then(table => {
								return table
								.where('tiershorse_id')
								.equals(parseInt(tiershorse_id))
								.invalid()
							})
						}
					}
				)
			}
			else {
				throw "toast.info_delete_failed"
			}
        },

        loadHorsePension: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_PENSION_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadPension", url)
            .catch(error => {
                console.error("HorseMixin::loadPension => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        loadCurrentPension: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_CURRENT_PENSION_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadCurrentPension", url)
            .catch(error => {
                console.error("HorseMixin::loadCurrentPension => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

       	getHorsesStallion: async function(heavy = true) {
            return this.$storage.db.t('horse')
            .then(table => {
                return table
                .where({
					horse_stallion: 1,
					horse_inactive: 0
				})
            })
            .then(col => {
                return col.transform(new HorseTransformer(heavy ? 'heavy' : null))
            })
        },

        loadHorseContacts: async function(horse_id) {
            return this.$storage.db.t('horse_contact')
            .then(table => {
                return table.where({horsecontact_valide: 1, horsecontact_horse: parseInt(horse_id)})
            })
            .then(col => {
                return col.transform(new HorseContactTransformer('withContactAndFonction'))
            })
        },

        loadHorseContact: async function(horse_id, contact_id) {
            return this.$storage.db.t('horse_contact')
            .then(table => {
                return table.where({horsecontact_valide: 1, horsecontact_horse: parseInt(horse_id), horsecontact_contact: parseInt(contact_id)})
            })
            .then(col => {
                return col.transform(new HorseContactTransformer())
            })
        },

        addHorseContact: async function(horse_id, contact_id) {
            const horsecontact_id = Common.getNegativeId()
            return this.$storage.db.t('horse_contact')
            .then(table => {
                return table.add({
                    horsecontact_id: horsecontact_id,
                    horsecontact_valide: 1,
                    horsecontact_contact: parseInt(contact_id),
                    horsecontact_horse: parseInt(horse_id)
                })
            })
            .then(col => {
                return horsecontact_id
            })
        },

        getHorseFemelle: async function() {
            return this.$storage.db.t('horse')
            .then(table => {
                return table
                .where({
					horse_sexe: "F",
					horse_inactive: 0
				})
            })
            .then(col => {
                return col.transform(new HorseTransformer())
            })
        },
        loadHorseTiers: async function(horse_id) {
            return this.$storage.db.t('tiers_horse')
			.then(table => {
				return table.where({
					tiershorse_horse: parseInt(horse_id),
					tiershorse_valide: 1
				})
				.and(tiershorse => tiershorse.tiershorse_fonction == 'PROPRIO')
				.toArray()
			})
			.then(async tiers_horses => {
				const tiers_ids = []
				tiers_horses.forEach((tiers_horse) => {
					tiers_ids.push(tiers_horse.tiershorse_tiers)
				})

				const table = await this.$storage.db.t('tiers')
				return table
					.where('tiers_id').anyOf(tiers_ids)
					.transform(new TierTransformer('light'))
			})
        },

		loadHorseTiersSecondaires: async function(tiers_id) {
			return this.$storage.db.t('tiers_horse')
			.then(table => {
				return table.where({
					tiershorse_tiers: parseInt(tiers_id),
					tiershorse_valide: 1
				})
				.and(tiershorse => tiershorse.tiershorse_fonction !== 'PROPRIO')
			})
			.then(col => {
				return col.transform(new TiersHorseTransformer('withHorseAndFonctionAndTiersContact'))
			})
		},

        sendCourrier: async function(horse_id, model_id) {
        	const params = {horse_id, model_id}
            const url = this.constructRoute(Constants.HORSE_ENVOIE_COURRIER_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::sendCourrier", url)
            .catch(error => {
                console.error("HorseMixin::sendCourrier => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
		},

		getSeasonMareStallionPreviousSeason: async function(start_date_current_season, horse_id) {
			let previous_season = await this.$storage.db.t('season')
				.then(table => {
					return table.toCollection()
					.filter(season => (season.season_end <= start_date_current_season))
				})
				.then(col => {
					return col.transform(new SeasonTransformer())
				})
				.then(col => {
					return _orderBy(col, ['season_end'], ['desc'])
				})
				.then(res => {
					if(res.length > 0) return res[0]
					return null
				})
			if(!previous_season) return false

			let season_mare = await this.$storage.db.t('season_mare')
				.then(table => {
					return table
					.where({
						seasonmare_season: parseInt(previous_season.season_id),
						seasonmare_horse: parseInt(horse_id)
					})
				})
				.then(col => {
					return col.transform(new SeasonMareTransformer())
				})

			if(!season_mare.length > 0) return false

			return this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table
				.where({
					seasonmarestallion_seasonmare: parseInt(season_mare[0].seasonmare_id),
					seasonmarestallion_default: 1
				})
			})
			.then(col => {
				return col.transform(new SeasonMareStallionTransformer('light'))
			})
		},

		getHorse: function (search_param) {
			return this.$storage.db.t('horse')
                .then(table => {
                    return table.where('horse_nom')
					.equalsIgnoreCase(search_param)
					.or('horse_sire')
					.equals(search_param)
					.or('horse_transpondeur')
					.equals(search_param)
                }).then(col => {
                    return col.transform(new HorseTransformer('light'))
                })
		},

        closePensionAndAddMouvementMort: async function (horse_id, date, date_mvt) {
			this.cloturePension(horse_id, date)

			const mouvement = {
				'mouvement_date': date_mvt,
				'mouvement_raison': this.getTrad("horse.deces"),
				'mouvement_label': upperCase(this.getTrad("horse.mort")),
				'mouvement_type': 3,
				'mouvement_horse': horse_id,
			}
			this.addMouvement(mouvement)
		},

		generatePedigreePdf: async function(mare_id, stallion_id) {
			const params = {
				mare_id: mare_id,
				stallion_id: stallion_id
			}
			const url = Constants.HORSE_PEDIGREE_CROISEMENT_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("HorseMixin::generatePedigreePdf", url, params, false)
			.catch(error => {
				console.error("HorseMixin::generatePedigreePdf => ERROR", error)
				return null
			})
			
			if(result) {
				Common.base64ToPdf(result, "croisement.pdf")
			}
			else {
				return result
			}
		},

		getHorseChildren: async function (horse_id) {
			let horse = await this.$storage.db.t('horse')
                .then(table => {
                    return table.where('horse_id')
					.equals(parseInt(horse_id))
                }).then(col => {
                    return col.first()
                })

			let horse_sireencode = null

			if(horse.horse_sire) {
				horse_sireencode = Common.get_sire_encode(horse.horse_sire)
			} 
			else {
				horse_sireencode = '_' + horse.horse_id
			}

			return this.$storage.db.t('horse_pedigree')
				.then(table => {
					return table.where('pedigree_sireencode_1')
					.equals(horse_sireencode)
					.or('pedigree_sireencode_2')
					.equals(horse_sireencode)
				}).then(col => {
					return PedigreeTransformer.process(col, 'withParentsAndChild')
				})
		},

		saveParentSireencode: async function (pedigree_id, sex, sire) {
			let horse_sireencode = sire
			if(!horse_sireencode.startsWith('_')) {
				horse_sireencode = Common.get_sire_encode(sire)
			}

			if(sex == 1) {
				await this.$storage.db.t('horse_pedigree')
				.then(table => {
					return table.update(
						parseInt(pedigree_id), {
							pedigree_sireencode_1: horse_sireencode
						})
				})
			}
			else if(sex == 2) {
				await this.$storage.db.t('horse_pedigree')
				.then(table => {
					return table.update(
						parseInt(pedigree_id), {
							pedigree_sireencode_2: horse_sireencode
						})
				})
			}

			return horse_sireencode
		},

		generatePdfFicheDeDepart: async function(horse_ids) {
			const params = {
				horse_ids: horse_ids
			}
			const url = Constants.HORSE_FICHE_DEPART_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("HorseMixin::generatePdfFicheDeDepart", url, params, false)
			.catch(error => {
				console.error("HorseMixin::generatePdfFicheDeDepart => ERROR", error)
				return null
			})
			
			if(result) {
				Common.base64ToPdf(result, "fiche_depart.pdf")
			}
			else {
				return result
			}
		},

		linkPedigreeFile: async function(horse_id, horse_pedigree, file) {
			horse_pedigree = parseInt(this.$sync.replaceWithReplicated('horse_pedigree', horse_pedigree))
			await this.uploadMediaFile(file, horse_pedigree, 'App\\Model\\Pedigree')

			// Après avoir upload le fichier, on dévalide les anciens
			const url = this.constructRoute(Constants.HORSE_CLEAN_PDF_PEDIGREE_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_post_api("HorseMixin::linkPedigreeFile", url, [], false)
			.catch(e => {
				console.error("HorseMixin::linkPedigreeFile => ERROR", e)
				throw e
			})
		},

		switchHorseCaVisibility: async function(horse_id, visibility) {
			return this.$storage.db.t('horse')
            .then(table => {
                return table.update(parseInt(horse_id), {
                    horse_display_ca: Boolean(visibility)
                })
            })
		},

		async print_infos_horse(horse_ids, format_id, model_id, skip, returnPDF = false) {
			const params = {
				horse_ids: horse_ids,
				format_id: format_id,
				model_id: model_id,
				skip: skip
			}
			const url = Constants.HORSE_INFOS_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("HorseMixin::print_infos_horse", url, params, false)
			.catch(error => {
				console.error("HorseMixin::print_infos_horse => ERROR", error)
				return null
			})
			
			if(result) {
				if(returnPDF) {
					return result
				}
				Common.base64ToPdf(result, "informations_chevaux.pdf")
			}
			else {
				return result
			}
		},

		async getHorsesCaUnacknowledged() {
			const url = Constants.HORSE_CA_UNACKNOWLEDGED_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("HorseMixin::getHorsesCaUnacknowledged", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("HorseMixin::getHorsesCaUnacknowledged => ERROR", e)
					return null
				})
		},

		async validUnacknowledgedHorseCa(tiers_horse_ids) {
			const url = Constants.HORSE_CA_ACKNOWLEDGE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("HorseMixin::getHorsesCaUnacknowledged", url, { tiers_horse_ids }, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("HorseMixin::getHorsesCaUnacknowledged => ERROR", e)
					return null
				})
		},

		async loadMaresNotFollowed(season_id) {
			let actes_type_repro = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where({
					actestype_codegroupe: 'REPRO'
				})
			}).then(col => {
				return ActeTypeTransformer.process(col)
			})

			let actestype_repro_ids = actes_type_repro.map((acte_type) => {
				return acte_type.actestype_id
			})

			let all_season_mare = await this.$storage.db.t('season_mare')
			.then(table => {
				return table
				.where({
					seasonmare_season: parseInt(season_id),
				})
			})
			.then(col => {
				return col.transform(new SeasonMareTransformer())
			})

			let all_seasonmare_horse = all_season_mare.map((season_mare) => {
				return season_mare.seasonmare_horse
			})

			let actes_repro = await this.$storage.db.t('horse_actes')
			.then(table => {
				return table.where('actes_horse')
				.anyOf(all_seasonmare_horse)
				.and(acte => acte.actes_actesstatut == 0)
				.and(acte => actestype_repro_ids.includes(acte.actes_actestype))
			}).then(col => {
				return ActeTransformer.process(col, 'liteWithNotes')
			})

			let actesrepro_horse_ids = actes_repro.map((acte) => {
				return acte.actes_horse
			})

			let all_seasonmare_horse_ids = all_seasonmare_horse.filter(id => !actesrepro_horse_ids.includes(id))
			all_seasonmare_horse_ids = [...new Set(all_seasonmare_horse_ids)]
			
			let all_seasonmare_horse_final = await this.$storage.db.t('season_mare')
			.then(table => {
				return table.where({
					seasonmare_season: season_id
				})
				.and(season_mare => all_seasonmare_horse_ids.includes(season_mare.seasonmare_horse))	
			}).then(col => {
				return SeasonMareTransformer.process(col, 'SeasonMareWithTranslatedStatus')
			})

			all_seasonmare_horse_final = _uniqBy(all_seasonmare_horse_final, 'horse.horse_id')
			return all_seasonmare_horse_final
		},

		sireAlreadyExists(sire, cle) {
			return this.$storage.db.t('horse')
			.then(table => {
				return table.where({
					horse_sire: sire,
					horse_cle: cle
				})
			}).then(col => {
				return col.first()
			})
		},

		async checkHorseAlive(horse_ids) {
			let horse_dead = 0

			await Common.asyncForEach(horse_ids, async(horse_id) => {
				if(horse_dead) {
					return
				}

				let horse = await this.getHorseById(horse_id)
				if(horse.horse_mort == 1) {
					horse_dead = 1
				}
			})

			if(horse_dead) {
				return false
			}
			
			return true
		},

		async getFoalMare(horse_id) {
			const pedigree = await this.$storage.db.t('horse_pedigree')
				.then(table => {
					return table.where('pedigree_horse')
					.equals(this.$sync.replaceWithReplicated('horse', horse_id))
					.first()
				})

			if(!pedigree || !pedigree.pedigree_sireencode_2) return false
			
			return await this.$storage.db.t('horse')
				.then(table => {
					return table.where('horse_sireencode')
					.equals(pedigree.pedigree_sireencode_2)
					.first()
				})
		},

		// Verifie si le cheval donné a un tiers
		async checkHorseHasTiersOnDate(horse_id, date) {
			let tiers_part = await this.loadTiersPart(horse_id)

			let tiers_horse_parts = []
			tiers_part.tiers_horse.forEach(tiers_horse => {
				if(tiers_horse.tiers_horse_part.length > 0) {
					tiers_horse_parts.push(tiers_horse.tiers_horse_part)
				}
			});

			tiers_horse_parts.forEach(all_parts => {
				let index = 0
				all_parts.forEach(part => {
					if(new Date(part.tiershorsepart_date) > date) {
						all_parts.splice(index, 1)
					}
					index++
				})
			})

			let parts_active_ondate = []
			tiers_horse_parts.forEach(all_parts => {
				if(all_parts.length > 0) {
					if(all_parts[all_parts.length - 1].tiershorsepart_propriete !== 0) {
						parts_active_ondate.push(all_parts[all_parts.length - 1])
					}
				}
			})
			
			// S'il n'y a rien dans parts active c'est que le cheval n'a pas de tiers à cette date, on retourne donc son nom pour l'afficher dans le toast
			if(parts_active_ondate.length == 0) {
				let horse = await this.getHorseById(horse_id)
				return horse.horse_nom
			}
			else {
				return null
			}
		},

		searchHorseByName: function (search_val) {
			return this.$storage.db.t('horse')
                .then(table => {
                    return table.where('horse_nom').startsWithIgnoreCase(search_val)
                }).then(col => {
                    return col.transform(new HorseTransformer('light'))
                })
		},

		getHorseAndParents: async function (sire) {
			const url = Constants.SEARCH_HORSE_AND_PARENTS_URL + '?sire=' + sire + '&licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_get_api("HorseMixin::getHorseAndParents", url, false, { sync: true }).catch(error => {
                console.error("HorseMixin::getHorseAndParents => ERROR", error)
                this.failureToast("toast.info_save_failed")
                return response.code_retour
            })

			return response
		},

		getHorseLieuStationnement: async function (horse_id) {
			const horse = await this.$storage.db.t('horse')
				.then(table => {
					return table.get(horse_id)
				})

			if(!horse.horse_lieustationnement) {
				return null
			}

			return this.$storage.db.t('tiers')
				.then(table => {
					return table.get(horse.horse_lieustationnement)
				})
				.then(tier => {
					return TierTransformer.process(tier, 'withPhone')
				})
		},

		assignLieuStationnement: function (horse_id, tiers_id) {
			return this.$storage.db.t('horse')
	            .then(table => {
	                return table.where('horse_id').equals(parseInt(horse_id)).modify({horse_lieustationnement: parseInt(tiers_id) })
				})
		},

		UnassignLieuStationnement: function (horse_id) {
			return this.$storage.db.t('horse')
	            .then(table => {
	                return table.where('horse_id').equals(parseInt(horse_id)).modify({horse_lieustationnement: null })
				})
		},

		addHorseBySire: async function(sire, stallion, safemode = false) {
			const url = Constants.SEARCH_SIRE_URL + '?sire=' + sire + '&valide=1&update=1&stallion='+stallion+'&licence_key='+Constants.USER_LICENCE_KEY+'&safemode='+safemode
            const response = await this.$request.request_get_api("HorseMixin::addHorseBySire", url).catch(error => {
                console.error("HorseMixin::addHorseBySire => ERROR", error)
                this.failureToast("toast.info_save_failed")
                return response.code_retour
            })

			return response
		},

		importDescendants: async function(sire, year) {
			const params = { 'sire': sire, 'year': year }
			const url = this.constructRoute(Constants.HORSE_IMPORT_DESCENDANTS_URL, params) + '?licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_get_api("HorseMixin::importDescendants", url)
			.catch(error => {
                console.error("HorseMixin::importDescendants => ERROR", error)
                this.failureToast("toast.info_save_failed")
                return response.code_retour
            })

			return response
		},

		async switchFormatPoulain(format) {
			const params = {
				format: format
			}

			const url = this.constructRoute(Constants.HORSE_SWITCH_FORMATS_POULAIN, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("HorseMixin::switchFormatPoulain", url, params, false)
			.catch(error => {
				console.error("HorseMixin::switchFormatPoulain => ERROR", error)
				return null
			})

			if(result) return result.retour
			return null
		},

		async getCurrentFormatPoulain() {
			return this.$storage.db.t('poulain_format_used')
			.then(table => {
				return table.toCollection().first()
			})
		},
		
		getHorseMaleAndIndefini: async function() {
            return this.$storage.db.t('horse')
            .then(table => {
                return table.where('horse_sexe')
					.equalsIgnoreCase('M')
					.or('horse_sexe')
					.equalsIgnoreCase('H')
					.or('horse_sexe')
					.equalsIgnoreCase('IND')
            })
            .then(col => {
                return col.transform(new HorseTransformer())
            })
        },

		getHorseFemelleAndIndefini: async function() {
            return this.$storage.db.t('horse')
            .then(table => {
                return table.where('horse_sexe')
					.equalsIgnoreCase('F')
					.or('horse_sexe')
					.equalsIgnoreCase('IND')
            })
            .then(col => {
                return col.transform(new HorseTransformer())
            })
        },

		async mergeHorses(horse_to_merge_id, horse_id) {
			const url = this.constructRoute(Constants.HORSE_MERGE, {horse_to_merge_id, horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("HorseMixin::mergeHorses", url)
			.catch(error => {
				console.error("HorseMixin::mergeHorses => ERROR", error)
				return null
			})

			return response
		},

		addHorseByTranspondeur: async function(transpondeurs) {
			const params = { 'transpondeurs': transpondeurs }
			const url = this.constructRoute(Constants.HORSE_ADD_BY_TRANSPONDEUR_URL, {}) + '?licence_key='+Constants.USER_LICENCE_KEY
            const response = await this.$request.request_post_api("HorseMixin::addHorseByTranspondeur", url, params, false)
			.catch(error => {
                console.error("HorseMixin::addHorseByTranspondeur => ERROR", error)
                return null
            })

			return response
		},

		getMailsCoproprios: async function(horse_id, tiers_id) {
			const horse = await this.loadTiersCurrentPart(horse_id)

			let mails = []
			if(horse) {
				horse.tiers_horse.forEach(th => {
					if(th.tiers.tiers_id != tiers_id && th.tiers_horse_part.tiershorsepart_contract
						&& th.tiers_horse_part.tiershorsepart_contract != 0 && th.tiers.tiers_mail && th.tiers.tiers_mail != '') {
						mails.push(th.tiers.tiers_mail)
					}
				})
			}

			return mails.join(', ')
		},

		getSireByMicroship: async function(microship) {
			const params = { transpondeur: microship }
			const url = this.constructRoute(Constants.GET_SIRE_BY_MICROSHIP_URL, {}) + '?licence_key='+Constants.USER_LICENCE_KEY
            const response = await this.$request.request_post_api("HorseMixin::getSireByMicroship", url, params, false)
			.catch(error => {
                console.error("HorseMixin::getSireByMicroship => ERROR", error)
                return null
            })

			return response
		},

		archiveHorses: async function(horse_ids) {
			let horse_noms = []

			await Common.asyncForEach(horse_ids, async (horse_id) => {
				const horse = await this.$storage.db.t('horse')
					.then(table => {
						return table.where('horse_id').equals(horse_id).first()
					})

				if(horse) {
					if(!horse.horse_residence_label) {
						await this.$storage.db.t('horse')
						.then(table => {
							return table.update(horse_id, {
								horse_inactive: 1
							})
						})
					}
					else {
						horse_noms.push(horse.horse_nom)
					}
				}
				else {
					this.failureToast('global.cheval_introuvable')
				}
			})

			if(horse_noms.length > 0) {
				if(horse_noms.length == 1) {
					this.failureToast('toast.horses_still_in_unique', false, {horse_noms: horse_noms[0]})
				}
				else {
					this.failureToast('toast.horses_still_in_multiple', false, {horse_noms: horse_noms.join(', ')})
				}
			}
		},

		unArchiveHorses: async function(horse_ids) {
			await Common.asyncForEach(horse_ids, async (horse_id) => {
				await this.$storage.db.t('horse')
				.then(table => {
					return table.update(horse_id, {
						horse_inactive: 0
					})
				})
			})
		},

		archiveOrUnarchiveHorse: async function (horse_id){
			const horse = await this.$storage.db.t('horse')
			.then(table => {
				return table.where('horse_id').equals(horse_id).first()
			})
			
			await this.$storage.db.t('horse')
			.then(table => {
				return table.update(horse_id, {
					horse_inactive: !horse.horse_inactive
				})
			})
		},
		
		getCharges: async function(horse_id, from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''

			let params = {horse_id}

			const url = `${this.constructRoute(Constants.HORSE_CHARGES_URL, params)}?licence_key=${Constants.USER_LICENCE_KEY}&start_date=${from}&end_date=${to}`
            const result = await this.$request.request_get_api("HorseMixin::getCharges", url, params, false)
            .catch(error => {
                console.error("HorseMixin::getCharges => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
		},
		
		getChargesPension: async function(horse_id, from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''

			let params = {horse_id}

			const url = `${this.constructRoute(Constants.HORSE_CHARGES_PENSION_URL, params)}?licence_key=${Constants.USER_LICENCE_KEY}&start_date=${from}&end_date=${to}`
            const result = await this.$request.request_get_api("HorseMixin::getChargesPension", url, params, false)
            .catch(error => {
                console.error("HorseMixin::getChargesPension => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
		},

		importHorses: async function(horses) {
            const url = this.constructRoute(Constants.HORSES_IMPORT, {horses}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const params = {horses: horses}
            const result = await this.$request.request_post_api("HorseMixin::importHorses", url, params, false)
            .catch(error => {
                console.error("HorseMixin::importHorses => ERROR", error)
                if(error.response.data.message != "")
                {
                    this.failureToast(error.response.data.message)
                }
                else
                {
                    this.failureToast("toast.info_save_failed")
                }
                return null
            })

            if(result) return result.retour
			return null

        },

		importHorsesWeatherby: async function(horses) {
            const url = Constants.HORSES_IMPORT_WEATHERBY + "?licence_key="+Constants.USER_LICENCE_KEY
			const params = {horses: horses}
            const result = await this.$request.request_post_api("HorseMixin::importHorsesWeatherby", url, params, false)
            .catch(error => {
                console.error("HorseMixin::importHorsesWeatherby => ERROR", error)
                if(error.response.data.message != "") {
                    this.failureToast(error.response.data.message)
                }
                else {
                    this.failureToast("toast.info_save_failed")
                }
                return null
            })

            if(result) return result.retour
			return null

        },

		getDpsByChildren: async function (horse_id) {
			const url = this.constructRoute(Constants.HORSE_DPS_BY_CHILDREN, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const response = await this.$request.request_get_api("HorseMixin::getDpsByChildren", url, false, { sync: true })
			.then(res => {
				return res.retour
			})
			.catch(error => {
				console.error("HorseMixin::getDpsByChildren => ERROR", error)
				return []
			})

			return response
		},

		getLeTrotResults: async function (keyword) {
			const params = {keyword: keyword}
			const url = this.constructRoute(Constants.LETROT_RESULTS_URL, {}) + '?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("HorseMixin::getLeTrotResults", url, params, false)
			.catch(error => {
                console.error("HorseMixin::getLeTrotResults => ERROR", error)
                return null
            })

            if(result) return result.retour
			return null
		},

		addLeTrotHorses: async function () {
			const url = this.constructRoute(Constants.LETROT_ADD_HORSES_URL, {}) + '?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("HorseMixin::addLeTrotHorses", url, {}, false)
			.catch(error => {
                console.error("HorseMixin::addLeTrotHorses => ERROR", error)
                return null
            })

            if(result) return result.retour
			return null
		},

		addHorseTiersSalon: async function(horse_id) {
			const tiers = await this.$storage.db.t('tiers')
			.then(table => {
				return table.where({
					tiers_rs: 'TEST Client'
				}).first()
			})

			let tiers_id = null
			if(!tiers) {
				tiers_id = Common.getNegativeId()
				this.$storage.db.t('tiers')
					.then(table => {
						return table.add({
							tiers_id: tiers_id,
							tiers_rs: "TEST Client",
							tiers_firstname: "Client",
							tiers_lastname: "Test",
							tiers_mail: "client@test.com",
							tiers_address1: "6 rue de Test",
							tiers_postalcode: "14000",
							tiers_town: "Caen",
							tiers_country: "75",
							tiers_moral: 1,
							tiers_entity: 0,
							tiers_valide: 1
						})
					})
				await this.$sync.force(true, true)
				tiers_id = parseInt(this.$sync.replaceWithReplicated('tiers', tiers_id))
			}
			else {
				tiers_id = tiers.tiers_id
			}

			const params = [{
				tiers_id: tiers_id,
				tiershorse_dps: 1,
				tiershorsepart_propriete: 100,
				tiershorsepart_pension: 100,
				tiershorsepart_frais: 100,
				tiershorsepart_gains: 100,
				tiershorsepart_contract: 100,
				tiershorsepart_entraineur: 100,
				tiershorsepart_jockey: 100
			}]

			return await this.addHorseTiers(horse_id, new Date(), params)
		},

		addHorsePensionSalon: async function(horse_id) {
			let pension_type = await this.loadAccountingPlansPensionType()
			if(pension_type.length == 0) {
				return false
			}
			let pension = pension_type[0].pension_type[0]
			let articles = [{
				horsepensionarticle_article: {
					articles_id: pension.article[0].articles_id
				},
				horsepensionarticle_ht: pension.article[0].articles_ht,
				horsepensionarticle_ht_before_discount: pension.article[0].articles_ht,
				horsepensionarticle_discount_type: null
			}]
			
			await this.addPension(horse_id, new Date(), null, pension.pension_id, false, articles, null)
		},

		getVideoConcours: async function(horse_id) {
			const url = this.constructRoute(Constants.HORSE_VIDEO_CONCOURS, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const response = await this.$request.request_get_api("HorseMixin::getVideoConcours", url, false, { sync: true })
			.then(res => {
				return res.retour
			})
			.catch(error => {
				console.error("HorseMixin::getVideoConcours => ERROR", error)
				return []
			})

			return response
		},

		addHorseWeatherbysID: async function(id) {
			const url = this.constructRoute(Constants.ADD_WEATHERBYS_ID_URL, { id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const response = await this.$request.request_post_api("HorseMixin::addHorseWeatherbysID", url, {}, false)
			.then(res => {
				return res
			})
			.catch(error => {
				console.error("HorseMixin::addHorseWeatherbysID => ERROR", error)
				return []
			})

			return response
		},

		translateWeatherbysSex: function(sex){
			return sex
		},

		translateWeatherbysCountry(nom) {
			const matches = nom.match(/\((.*?)\)/)
			if (!matches) return null
			let countryCode = matches[1].toUpperCase();
			
			if (!countryCode) return null;
	
			return Countries["weatherbys"][countryCode]
		},

		translateWeatherbysNom(nom){
			const matches = nom.match(/\((.*?)\)/)
			if (!matches) return nom
			nom = nom.replace(matches[0], '')
			return nom
		},

		translateWeatherbysDate(date){
			return date.split('T')[0]
		},

        getHorseByResidence: async function(residence_id) {
			return this.$storage.db.t('horse_residence')
            .then(table => {
                return table.where({
					horseresidence_lieu: parseInt(residence_id),
				})
            })
            .then(col => {
				return col.primaryKeys()
			})
        },

       	/**
		 * récupère le tiers principal d'un cheval
		 * d'abord le tiers avec la fonction Propriétaire
		 * puis le tiers qui a le max de % de contrat
		 */
        getPrincipalTiers: async function(horse_id) {
			let tiers = await this.loadTiersHeavyCurrentPart(horse_id)

			let tiers_proprio = tiers.find(tier => tier.tiershorse_dps)

			if(tiers_proprio !== undefined) {
				return tiers_proprio.tiers
			}

			let part_contract = tiers.map(tier => tier.tiers_horse_part.tiershorsepart_contract)
			let max_part = Math.max(...part_contract)
			let max_index = part_contract.indexOf(max_part)

			if(tiers[max_index]) {
				return tiers[max_index].tiers
			}

			return null
        }
	}
}

export default HorseMixin
